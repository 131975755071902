import Vue from 'vue'
import Vuex from 'vuex'
import accesscode from './modules/accesscode'
import attend from './modules/attend'
import account from './modules/account'
import course from './modules/course'
import createattendsrequest from './modules/createattendsrequest'
import lessoncompletionrequest from './modules/lessoncompletionrequest'
import lessonuncompletionrequest from './modules/lessonuncompletionrequest'
import loginrequest from './modules/loginrequest'
import passwordresetrequest from './modules/passwordresetrequest'
import registrationrequest from './modules/registrationrequest'
import requestpasswordresetrequest from './modules/requestpasswordresetrequest'
import state from './modules/state'
import topics from './modules/topics'
import lessons from './modules/lessons'
import isso from './modules/isso'
import updateattendstaterequest from './modules/updateattendstaterequest';
import buddy from './modules/buddy';
import createreviewrequest from './modules/createreviewrequest';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accesscode,
    attend,
    account,
    course,
    createattendsrequest,
    lessoncompletionrequest,
    lessonuncompletionrequest,
    loginrequest,
    passwordresetrequest,
    registrationrequest,
    requestpasswordresetrequest,
    state,
    topics,
    lessons,
    isso,
    updateattendstaterequest,
    buddy,
    createreviewrequest
  }
})
