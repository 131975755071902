import Vue from 'vue';
import Vuex from'vuex';
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import TextAreaAutosize from 'vue-textarea-autosize';
import VueTour from 'vue-tour';
import App from './App.vue';
import PlayerBox from "./components/lesson/PlayerBox";
import ExerciseTabbed from "./components/lesson/ExerciseTabbed";
import VideoGroup from "./components/lesson/VideoGroup";
import BuddyVideos from "./components/lesson/BuddyVideos";
import DownloadCard from "./components/lesson/DownloadCard";
import Worksheet from "./components/lesson/Worksheet";
import Accordion from "./components/lesson/Accordion";
import ReviewModal from "./components/lesson/ReviewModal";
import CheckedInput from "./components/lesson/CheckedInput";
import CheckedDropdown from "./components/lesson/CheckedDropdown";
import MaphiExercise from "./components/maphi/maphi-exercise";
import MaphiWindow from "./components/maphi/MaphiWindow";

import './assets/scss/theme.scss';
import './assets/fonts/feather/feather.css';
import 'vue-tour/dist/vue-tour.css';

import { setAccountId } from "./store/modules/account/storage";
import { setToken } from "./store/modules/loginrequest/storage";

import router from './router';

if (process.env.VUE_APP_IS_SAMPLE) {
  setAccountId('/accounts/1');
  setToken({
    token: 'test',
    validUntil: '2030-01-01T00:00:00+00:00'
  });
}

import store from './store';

Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(TextAreaAutosize);
Vue.use(VueTour);

Vue.component('PlayerBox', PlayerBox);
Vue.component('ExerciseTabbed', ExerciseTabbed);
Vue.component('VideoGroup', VideoGroup);
Vue.component('BuddyVideos', BuddyVideos);
Vue.component('DownloadCard', DownloadCard);
Vue.component('Worksheet', Worksheet);
Vue.component('Accordion', Accordion);
Vue.component('ReviewModal', ReviewModal);
Vue.component('CheckedInput', CheckedInput);
Vue.component('CheckedDropdown', CheckedDropdown);
if (process.env.VUE_APP_MAPHI_FEATURE === 'on') {
  Vue.component('MaphiExercise', MaphiExercise);
  Vue.component('MaphiWindow', MaphiWindow);
  window.cleanupMaphi = () => {};
}
Vue.component('DynamicTemplate', {
  name: 'DynamicTemplate',
  props: ['content'],
  render: function (h) {
    return h({ template: '<div>' + this.content + '</div>', inject: ['context'] }, this.$slots.default);
  }
});
Vue.config.productionTip = false;


Vue.prototype.$isSample = Boolean(process.env.VUE_APP_IS_SAMPLE);

export default new Vue({
  data() {
    return {
      showLogin: false,
    }
  },
  methods: {
    toggle() {
      this.showLogin = !this.showLogin;
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  store,
  router,
  render: h => h(App),
}).$mount('#app');
