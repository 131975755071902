import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

export const retrieve = ({ commit }, { id }) => {
  commit(types.TOGGLE_LOADING);

  return fetch(`/lessons/${id}`)
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING);
      commit(types.ADD_ITEM, { item: data });
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};
